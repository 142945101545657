import { gql } from "@apollo/client";

export const GET_VISITS = gql`
  query Visits($profileId: Int) {
    visits(profileId: $profileId) {
      id
      paidStatusShort
      priceInfo {
        price
      }
      user {
        id
        firstName
        lastName
      }
      event {
        id
        date
        title
        duration
        enrollEnabled
        # visitSet {
        #   id
        # }
        activity {
          id
          slug
          title
        }
        instructors {
          id
          name
        }
        zone {
          id
          title
        }
        # payments {
        #   choice
        #   title
        # }
        template {
          declineDeadline
        }
        color
        textColor
      }
      authorized
      paid
      # paidByCard {
      #   id
      #   dueDate
      #   template {
      #     title
      #     subtitle
      #     group {
      #       title
      #     }
      #   }
      # }
      refundable
    }
  }
`;

export const GET_VISITS_HISTORY = gql`
  query Visits($page: Int, $pageSize: Int, $profileId: Int) {
    visits: visitHistory(page: $page, pageSize: $pageSize, profileId: $profileId) {
      id
      clientPresent
      paidStatusShort
      # price
      priceInfo {
        price

        regularPrice
        bonusTitle
      }
      user {
        id
        firstName
        lastName
      }
      event {
        id
        date
        title
        duration
        activity {
          id
          slug
          title
          description
          image
        }
        instructors {
          id
          name
        }
        zone {
          id
          title
        }
        # payments {
        #   choice
        #   title
        # }
        template {
          declineDeadline
        }

        color
        textColor
      }
      authorized
      paid
      # paidByCard {
      #   id
      #   dueDate
      #   template {
      #     title
      #     subtitle
      #     group {
      #       title
      #     }
      #   }
      # }
      refundable
    }
  }
`;

const FRAGMENT_ADDITIONAL_FIELDS = gql`
  fragment EventAdditionalFields on ClientEventDetailType {
    date
    status
    activity {
      id
      slug
      title
      description
      image
    }

    visitSet {
      id
      paidStatus
      paid
      paidByCard {
        dueDate
        amount
        template {
          title
          subtitle
          group {
            title
          }
        }
      }
      payments {
        choice
        title
        description
      }

      status
      visitOptions {
        id
        optionId
        title
        price
        image
        description

        paid
      }
      refundable

      cards {
        id
        dueDate
        amount
        activated
        template {
          title
          subtitle
          # amount
          # priceInfo {
          #   price
          #   bonusTitle
          #   regularPrice
          # }
        }
      }
      authorized
      user {
        id
        name
        avatar
      }
    }
    duration
    description
    descriptionHtml
    image
    instructors {
      id
      name
      instructorDescription
      instructorImage
    }
    hall {
      id
      title
      address
    }
    color
    textColor
    variant
  }
`;

export const GET_CLIENT_EVENT_BY_PROFILE = gql`
  ${FRAGMENT_ADDITIONAL_FIELDS}
  query ClientEventDetail(
    $eventId: Int!
    $familyId: Int
    $promocode: String
    $showPayments: Boolean! # $clientReturnUrl: String!
  ) {
    event: clientEvent(
      id: $eventId
      #!  profileId: $familyId,
      familyProfileId: $familyId
      promocode: $promocode
    ) {
      id
      pilotEnabled
      title
      enrollEnabled
      promocodesEnabled
      account
      guestVisitEnabled
      phoneConfirmationRequired
      emailConfirmationRequired
      priceInfo {
        price: priceFloat
        regularPrice: regularPriceFloat
        bonusTitle
      }
      payments {
        choice
        title
        description
      }
      options {
        id
        title
        description
        image
        price
      }
      visitors {
        id
        userId
        name
        birthday
        gender
        avatar
        showInEvent
        # about
        # instagram

        # eventTemplates {
        #   title
        #   color
        #   backgroundColor
        # }
        # loyalty {
        #   title
        #   discount
        # }
      }
      cards {
        id
        dueDate
        activated
        amount
        template {
          title
          subtitle
        }
      }

      ...EventAdditionalFields @include(if: $showPayments)
    }
  }
`;

export const GET_CLIENT_EVENT_OFFER = gql`
  query ClientEventDetail($id: Int!) {
    offerData: clientEvent(id: $id) {
      id
      offer
    }
  }
`;

// export const GET_CLIENT_EVENT = gql`
//   query ClientEventDetail($visitId: Int) {
//     clientEvent(id: $visitId) {
//       id
//       date
//       title
//       status
//       duration
//       activity {
//         id
//         slug
//         title
//         description
//         image
//       }
//       zone {
//         id
//         title
//       }

//       instructors {
//         id
//         name
//         isInstructorVisible
//       }
//       color
//       textColor
//       visitSet {
//         id
//         refundable

//         paid
//         user {
//           id
//           firstName
//           lastName
//         }
//       }
//     }
//   }
// `;

export const GET_EVENT = gql`
  query Event($eventId: Int) {
    event(id: $eventId) {
      id
      date
      title
      enrollEnabled
      status
      tagTitle
      tagDescription
      tagKeywords
      ogType
      ogSiteName
      ogTitle
      ogDescription
      ogImage
      ogImageWidth
      ogImageHeight
      ogImageType
      ogImageAlt

      activity {
        id
        slug
        title
        description
        image
      }

      duration
      description
      descriptionHtml
      image
      price

      # priceInfo {
      #   price: priceFloat
      #   regularPrice: regularPriceFloat
      #   bonusTitle
      # }
      instructors {
        id
        name
        instructorDescription
        instructorImage
      }
      hall {
        id
        title
        address
      }
      color
      textColor
    }
  }
`;

// export const USER_PROFILE_FRAGMENT = gql`
//   fragment userProfileFragment on ProfileType {
//     id
//     firstName
//     lastName
//   }
// `;
// ${USER_PROFILE_FRAGMENT}
// ...userProfileFragment

// export const VISIT_SET_FRAGMENT = gql`
//   fragment visitSetFragment on VisitDetailType {
//     id
//     refundable
//     paid
//     user {
//       id
//       firstName
//       lastName
//     }
//   }
// `;

export const GET_CLIENT_EVENTS = gql`
  query ClientEvents(
    $startDate: Date
    $endDate: Date
    $page: Int
    $pageSize: Int
    $activity: Int
    $instructor: Int
    $template: Int
    $time: Time
    $zone: Int
    $isMorningTime: Boolean
    $isAfternoonTime: Boolean
    $isEveningTime: Boolean
    $myVisits: Boolean
    $hallId: Int!
  ) {
    events: clientEvents(
      startDate: $startDate
      endDate: $endDate
      page: $page
      pageSize: $pageSize
      activityId: $activity
      instructorId: $instructor
      templateId: $template
      time: $time
      zone: $zone
      morning: $isMorningTime
      day: $isAfternoonTime
      evening: $isEveningTime
      myVisits: $myVisits
      hall: $hallId
    ) {
      id
      date
      title
      status
      duration
      enrollEnabled
      activity {
        id
        slug
        title
        description
        image
      }
      zone {
        id
        title
      }
      instructors {
        id
        name
        isInstructorVisible
      }
      template {
        id
        title
      }
      color
      textColor

      visitSet {
        id
        refundable
        paid
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query Events(
    $startDate: Date
    $endDate: Date
    $page: Int
    $pageSize: Int
    $activity: Int
    $instructor: Int
    $template: Int
    $time: Time
    $zone: Int
    $isMorningTime: Boolean
    $isAfternoonTime: Boolean
    $isEveningTime: Boolean
    # $myVisits: Boolean
    $hallId: Int!
  ) {
    events(
      startDate: $startDate
      endDate: $endDate
      page: $page
      pageSize: $pageSize
      activityId: $activity
      instructorId: $instructor
      templateId: $template
      time: $time
      zone: $zone
      morning: $isMorningTime
      day: $isAfternoonTime
      evening: $isEveningTime
      # myVisits: $myVisits
      hall: $hallId
    ) {
      id
      date
      title
      duration
      enrollEnabled
      activity {
        id
        slug
        title
        description
        image
      }
      zone {
        id
        title
      }
      instructors {
        id
        name
        isInstructorVisible
      }
      template {
        id
        title
      }
      color
      textColor
    }
  }
`;
